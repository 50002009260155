<template>
    <div class="mb-2 mt-2">
        <table class="innerClassTable" style="width:100%;max-width:550px">
            <tr>
                <th class="primary white--text" style="width:80px;text-align: left;border-bottom-color: #ffffff !important;">
                    {{ languagePack("dnsTable", 'query') }}
                </th>
                <td colspan="5">
                    {{item.query}}
                </td>
            </tr>
            <tr>
                <th class="primary white--text" style="text-align: left;border-bottom-color: #ffffff !important;">
                    {{ languagePack("dnsTable", 'rtt') }}
                </th>
                <td colspan="5">
                  {{convertMs(item.rtt)}}
                </td>
            </tr>
             <tr class="primary white--text">
                 <th style="width:80px;border-left-color:#2b5593;text-align: left;">
                     Answers
                 </th>
                 <th>
                     #
                 </th>
                <th :style="rightBorderColor(index)" v-for="(answersTitle,title,index) in sortedItems(headerList)" :key="title" >
                    <span> {{ languagePack("dnsTable", title) }}</span>
                </th>
            </tr>
            <tr style="text-align: right;"  v-for="(answers,index) in answersList" :key="index">
                <td class="primary"></td>
                <td>{{index+1}}</td>
                       <td  v-for="(value,inner) in sortedItems(answers)" :key="inner"  >
                         <span>{{ value }} <template v-if="inner=='ttl'"> s</template></span>
                 </td>
            </tr>
         </table>
    </div>
</template>
<script>
  import { objectCustomSort,convertMs } from "@/js/helper.js";

  export default {
    props: {
      item: Object,
    },
    methods: {
      convertMs(value){
        return convertMs(value);
      },
      sortedItems(value){
        var sortOrder = ['class','type','value','ttl'];
        return objectCustomSort(sortOrder,value);
      },
      rightBorderColor(index){
        let bcStyle = "border-right-color:#FFFFFF";
        if(Object.keys(this.answersList[0]).length-1==index){
          bcStyle = "border-right-color:#2b5593";
        }
        return bcStyle;
      }
    },
    computed: {
      answersList: function () {
        return this.item.answers;
      },
      headerList: function(){
        return this.answersList[Object.keys(this.answersList)[0]];
      }
      },
    };
</script>
<style scoped>
    table {
        border-collapse: collapse;
    }
    table td {
        border: thin solid rgba(0, 0, 0, 0.12);
        padding: 5px;
    }
    table th {
        border: thin solid white;
        padding: 5px;
    }
    .coloredBack,
    .coloredBack:hover {
        background-color: #deebff;
    }
</style>