<template>
  <div class="mb-2">
    <table
      v-if="item.downlinkStatus || item.uplinkStatus"
      style="width: 100%; max-width: 600px"
      class="innerClassTable"
    >
      <colgroup>
        <col style="width: 11.9101%" />
        <col style="width: 25.3805%" />
        <col v-if="item.uplinkStatus" style="width: 10%" />
        <col v-if="item.downlinkStatus" style="width: 10%" />
      </colgroup>
      <tbody>
        <tr class="primary white--text">
          <th colspan="2">
            <br />
          </th>
          <th v-if="item.uplinkStatus">
            <span>Uplink</span>
          </th>
          <th v-if="item.downlinkStatus">
            <span>Downlink</span>
          </th>
        </tr>
        <tr>
          <td colspan="2">Average Loss Ratio</td>
          <td v-if="item.uplinkStatus" style="text-align: right">
            {{ item.uplink.results.summary.avgLossRatio }}
          </td>
          <td v-if="item.downlinkStatus" style="text-align: right">
            {{ item.downlink.results.summary.avgLossRatio }}
          </td>
        </tr>
        <tr class="coloredBack">
          <td rowspan="3">Errors</td>
          <td colspan="1">Loss</td>
          <td v-if="item.uplinkStatus" style="text-align: right">
            {{ item.uplink.results.summary.seqErrLoss }}
          </td>
          <td v-if="item.downlinkStatus" style="text-align: right">
            {{ item.downlink.results.summary.seqErrLoss }}
          </td>
        </tr>
        <tr class="coloredBack">
          <td colspan="1">Out of Order</td>
          <td v-if="item.uplinkStatus" style="text-align: right">
            {{ item.uplink.results.summary.seqErrOoo }}
          </td>
          <td v-if="item.downlinkStatus" style="text-align: right">
            {{ item.downlink.results.summary.seqErrOoo }}
          </td>
        </tr>
        <tr class="coloredBack">
          <td colspan="1">Duplicate</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.summary.seqErrDup }}
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.summary.seqErrDup }}
          </td>
        </tr>
        <tr>
          <td colspan="2">One Way Delay Minimum</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.minimum.owd }} ms
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.minimum.owd }} ms
          </td>
        </tr>
        <tr class="coloredBack">
          <td rowspan="3">One Way Delay Variation</td>
          <td colspan="1">Min</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.summary.owdVarMin }} ms
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.summary.owdVarMin }} ms
          </td>
        </tr>
        <tr class="coloredBack">
          <td colspan="1">Max</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.summary.owdVarMax }} ms
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.summary.owdVarMax }} ms
          </td>
        </tr>
        <tr class="coloredBack">
          <td colspan="1">Avg</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.summary.owdVarAvg }} ms
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.summary.owdVarAvg }} ms
          </td>
        </tr>
        <tr>
          <td colspan="2">RTT Minimum</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.minimum.rtt }} ms
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.minimum.rtt }} ms
          </td>
        </tr>
        <tr class="coloredBack">
          <td rowspan="2">RTT Variation</td>
          <td colspan="1">Min</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.summary.rttVarMin }} ms
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.summary.rttVarMin }} ms
          </td>
        </tr>
        <tr class="coloredBack">
          <td colspan="1">Max</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.summary.rttVarMax }} ms
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.summary.rttVarMax }} ms
          </td>
        </tr>
        <tr>
          <td rowspan="5">Bandwidth</td>
          <td colspan="1">Avg (Layer 3/IP)</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.summary.avgL3Mbps }} Mbps
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.summary.avgL3Mbps }} Mbps
          </td>
        </tr>
        <tr>
          <td colspan="1">Max (Layer 3/IP)</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.maximum.L3Mbps }} Mbps
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.maximum.L3Mbps }} Mbps
          </td>
        </tr>
        <tr>
          <td colspan="1">Max (Layer 2/Eth)</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.maximum.L2Mbps }} Mbps
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.maximum.L2Mbps }} Mbps
          </td>
        </tr>
        <tr>
          <td colspan="1">Max (Layer 1/Eth)</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.maximum.L1Mbps }} Mbps
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.maximum.L1Mbps }} Mbps
          </td>
        </tr>
        <tr>
          <td colspan="1">Max (Layer 1/Eth+VLAN)</td>
          <td v-if="item.uplinkStatus" colspan="1" style="text-align: right">
            {{ item.uplink.results.maximum.L0Mbps }} Mbps
          </td>
          <td v-if="item.downlinkStatus" colspan="1" style="text-align: right">
            {{ item.downlink.results.maximum.L0Mbps }} Mbps
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="mt-3"
      v-if="item.downlinkStatus == false && item.downlinkMessage != ''"
    >
      Downlink: {{ item.downlinkMessage }}
    </div>
    <div
      class="mt-3"
      v-if="item.uplinkStatus == false && item.uplinkMessage != ''"
    >
      Uplink: {{ item.uplinkMessage }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}
table td {
  border: thin solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
table th {
  border: thin solid rgba(255, 255, 255);
  padding: 5px;
}
.coloredBack,
.coloredBack:hover {
  background-color: #deebff;
}
</style>