<template>
  <div class="mb-2">
    <table class="innerClassTable" style="width: 100%; max-width: 150px">
      <tbody>
      <tr class="primary white--text">
        <template v-for="(value,index) in newItems">
          <th v-if="index!='host'" :key="index" class="primary">
            <span style="white-space: nowrap;"> {{ languagePack("traceRouteTable", index) }}</span>
          </th>
        </template>
      </tr>
      <tr>
        <template v-for="(value,index) in newItems">
          <td v-if="index!='host'" style="text-align: center;white-space: nowrap;"   :key="index">
            <span >{{ value }}</span>
          </td>
        </template>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
    import { objectCustomSort,convertMs } from "@/js/helper.js";
    export default {
        props: {
            item:  [Object,String],//accept both
        },
        computed: {
            newItems(){
                //console.log("--->",this.item);
                var sortOrder = ['ipVersion','protocol','port','host'];
                return objectCustomSort(sortOrder,this.item);
            }
        },
        methods: {
            convertMs(value){
                return convertMs(value);
            },
        }
    };
</script>
<style scoped>
  table {
    border-collapse: collapse;
  }
  table td {
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 5px;
  }
  table th {
    border: thin solid rgba(255, 255, 255);
    padding: 5px;
  }
  .coloredBack,
  .coloredBack:hover {
    background-color: #deebff;
  }
</style>