<template>
    <div class="mb-2">
        <table class="innerClassTable" style="width: 100%; max-width: 450px">
            <tbody>
            <tr class="primary white--text">
                <template v-for="(value,index) in newItems">
                    <th v-if="index!='host'" :key="index" class="primary">
                        <span > {{ languagePack("libreSpeedTestTable", index) }}</span>
                    </th>
                </template>
            </tr>
            <tr>
                <template v-for="(value,index) in newItems">
                    <td v-if="index!='host'" style="text-align: center;white-space: nowrap;"   :key="index">
                        <span v-if="index=='duration'">{{ value }} s</span>
                        <span v-else>{{ value }}</span>
                    </td>
                </template>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
    import { objectCustomSort,convertMs } from "@/js/helper.js";
    export default {
        props: {
            item:  Object,
        },
        computed: {
            newItems(){
                //console.log("--->",this.item);
                var sortOrder = ['ipVersion','uplink','downlink','duration','parallel','host'];

                return objectCustomSort(sortOrder,this.item);
            }
        },
        methods: {
            convertMs(value){
                return convertMs(value);
            },
        }
    };
</script>
<style scoped>
    table {
        border-collapse: collapse;
    }
    table td {
        border: thin solid rgba(0, 0, 0, 0.12);
        padding: 5px;
    }
    table th {
        border: thin solid rgba(255, 255, 255);
        padding: 5px;
    }
    table th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4) {
        border-right-color: white !important;
    }
    .coloredBack,
    .coloredBack:hover {
        background-color: #deebff;
    }
</style>