<template>
    <div class="mb-2">
         <table class="innerClassTable" style="width: 100%; max-width: 250px">
            <tbody>
            <tr class="primary white--text">
                <template v-for="(value,index) in newItems.targets">
                    <th  v-if="index!='useDefaultDnsServer' && index!='tos' && index!='useFirstDnsResultOnly'" :key="index" class="primary">
                        <span style="white-space: nowrap;"> {{ languagePack("rttCheckRequestTable", index) }}</span>
                    </th>
                </template>
                <template v-for="(value,index) in newItems.parameters">
                    <th  :key="index" class="primary">
                        <span style="white-space: nowrap;"> {{ languagePack("rttCheckRequestTable", index) }}</span>
                    </th>
                </template>
            </tr>
            <tr>
               <template v-for="(value,index) in newItems.targets">
                <td v-if="index!='useDefaultDnsServer' && index!='tos' && index!='useFirstDnsResultOnly'" style="text-align: right;white-space: nowrap;"   :key="index">
                    <template v-if="index!='ip'">
                        <div v-for="(targetValue,targetIndex) in value "  :key="targetIndex" >{{targetValue}}</div>
                    </template>
                    <template v-else>
                        <div> {{value}}</div>
                    </template>
                </td>
            </template>
                <template v-for="(value,index) in newItems.parameters">
                    <td  style="text-align: right;white-space: nowrap;"   :key="index">
                        <span>{{ value }}</span>
                    </td>
                </template>

            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
  //import { objectCustomSort,convertMs } from "@/js/helper.js";
  import { convertMs } from "@/js/helper.js";
  export default {
    props: {
      item:  Object,
    },
    computed: {
      newItems(){
        // console.log("--->",this.item);//{"target":"192.168.1.1","packet":56,"ipVersion":"ipv4","ttl":64,"count":4}
        //var sortOrder = ['ipVersion','count','ttl','packet','target'];
        //return objectCustomSort(sortOrder,this.item);
        return this.item;
      }
    },
    methods: {
      convertMs(value){
        return convertMs(value);
      },
    }
  };
</script>
<style scoped>
    table {
        border-collapse: collapse;
    }
    table td {
        border: thin solid rgba(0, 0, 0, 0.12);
        padding: 5px;
    }
    table th {
        border: thin solid rgba(255, 255, 255);
        padding: 5px;
    }
    table th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5) {
        border-right-color: white !important;
    }
    .coloredBack,
    .coloredBack:hover {
        background-color: #deebff;
    }
</style>