<template>
    <div class="mb-2">
        <table v-if="(typeof newItems.message!='undefined' && newItems.message=='') || typeof newItems.message=='undefined'" style="width: 100%; max-width: 360px" class="innerClassTable">
            <colgroup>
                <col style="width: 33%" />
                <col style="width: 33%" />
                <col style="width: 33%" />
            </colgroup>
            <tbody>
            <tr class="primary white--text">
                <template v-for="(value,index) in newItems">
                    <th v-if="index!='message'" :key="index" class="primary">
                        <span > {{ languagePack("libreSpeedTestTable", index) }}</span>
                    </th>
                </template>
            </tr>
            <tr>
                <template v-for="(value,index) in newItems">
                    <td style="text-align: center;min-width:75px !important;white-space:nowrap;" v-if="index!='message'" :key="index">
                        <span v-if="index=='jitter' || index=='ping'"> {{value.toFixed(2)}} ms</span>
                        <span v-else>{{ toMmbs(value) }}</span>
                    </td>
                </template>
            </tr>
            </tbody>
        </table>
        <div v-else>
            {{newItems.message}}
        </div>
    </div>
</template>
<script>
  import { convertMs,objectCustomSort } from "@/js/helper.js";

  export default {
  props: {
    item: Object,
  },
  computed: {
      newItems(){
        var sortOrder = ['uplink','downlink','jitter','ping','message'];
        return objectCustomSort(sortOrder,this.item);
      }
    },
  methods: {
    toMmbs(value) {
      return value.toFixed(2) + " Mbit/s";
    },
    convertMs(value){
      return convertMs(value);
    }
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}
table td {
  border: thin solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
table th {
  border: thin solid rgba(255, 255, 255);
  padding: 5px;
}
table th:nth-child(1), th:nth-child(2), th:nth-child(3) {
    border-right-color: white !important;
}
.coloredBack,
.coloredBack:hover {
  background-color: #deebff;
}
</style>