import { render, staticRenderFns } from "./xbasicModemTable.vue?vue&type=template&id=608c90c6&scoped=true&"
import script from "./xbasicModemTable.vue?vue&type=script&lang=js&"
export * from "./xbasicModemTable.vue?vue&type=script&lang=js&"
import style0 from "./xbasicModemTable.vue?vue&type=style&index=0&id=608c90c6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "608c90c6",
  null
  
)

export default component.exports