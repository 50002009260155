<template>
  <div class="mb-2">
    <table
      class="innerClassTable"
      v-if="(typeof item.uplink !== 'undefined' &&
             Object.keys(item.uplink).length &&
             typeof item.uplink.error === 'undefined') ||
            (typeof item.downlink !== 'undefined' &&
             Object.keys(item.downlink).length &&
             typeof item.downlink.error === 'undefined')"
      style="width: 100%; max-width: 360px"
    >
      <colgroup>
        <col style="width: 11.9101%" />
        <col style="width: 25.3805%" />
        <col v-if="item.uplinkStatus" style="width: 10%" />
        <col v-if="item.downlinkStatus" style="width: 10%" />
      </colgroup>
      <tbody>
        <tr class="primary white--text">
          <th colspan="2">
            <br />
          </th>
          <th v-if="typeof item.uplink !== 'undefined'">
            <span>Uplink</span>
          </th>
          <th v-if="typeof item.downlink !== 'undefined'">
            <span>Downlink</span>
          </th>
        </tr>
        <tr>
          <td colspan="2">Bitrate</td>
          <td colspan="1"
                style="text-align: right">
            <template  v-if="typeof item.uplink !== 'undefined' &&
                             typeof item.uplink.end.sum_sent !== 'undefined' &&
                             typeof item.uplink.end.sum_sent.bits_per_second !== 'undefined'"
             >
              {{ convertMBits(item.uplink.end.sum_sent.bits_per_second) }}
            </template>
            <template v-else-if="typeof item.uplink !== 'undefined' &&
                                 protocol==='udp' &&
                                 typeof item.uplink.end.sum !== 'undefined' &&
                                 typeof item.uplink.end.sum.bits_per_second !== 'undefined'"
            >
             {{ convertMBits(item.uplink.end.sum.bits_per_second) }}
          </template>
          </td>
          <td colspan="1"
              style="text-align: right">
              <template
                      v-if="typeof item.downlink !== 'undefined' &&
                  typeof item.downlink.end.sum_received !== 'undefined' &&
                  typeof item.downlink.end.sum_received.bits_per_second !== 'undefined'   "

              >
                 {{ convertMBits(item.downlink.end.sum_received.bits_per_second) }}
              </template>
              <template
                      v-else-if="typeof item.downlink !== 'undefined' &&
                       protocol==='udp' &&
                       typeof item.downlink.end.sum !== 'undefined' &&
                       typeof item.downlink.end.sum.bits_per_second !== 'undefined'"
              >
                  {{ convertMBits(item.downlink.end.sum.bits_per_second) }}
              </template>
          </td>
        </tr>
        <tr>
          <td colspan="2">Transfer</td>
          <td colspan="1"
              style="text-align: right">
              <template
                      v-if="typeof item.uplink !== 'undefined' &&
                  typeof item.uplink.end.sum_sent !== 'undefined' &&
                  typeof item.uplink.end.sum_sent.bytes !== 'undefined'"

              >
                  {{ convertByte2MB(item.uplink.end.sum_sent.bytes) }}
              </template>
              <template
                      v-else-if="typeof item.uplink !== 'undefined' &&
                         protocol==='udp' &&
                         typeof item.uplink.end.sum !== 'undefined' &&
                         typeof item.uplink.end.sum.bytes !== 'undefined'"

              >
                  {{ convertByte2MB(item.uplink.end.sum.bytes) }}
              </template>
          </td>
         <td  colspan="1"
              style="text-align: right">
             <template
                     v-if="typeof item.downlink !== 'undefined' &&
                  typeof item.downlink.end.sum_received !== 'undefined' &&
                  typeof item.downlink.end.sum_received.bytes !== 'undefined'"

             >
                 {{ convertByte2MB(item.downlink.end.sum_received.bytes) }}
             </template>
             <template
                     v-else-if="typeof item.downlink !== 'undefined' &&
                         protocol==='udp' &&
                         typeof item.downlink.end.sum!== 'undefined' &&
                         typeof item.downlink.end.sum.bytes !== 'undefined'"
             >
                 {{ convertByte2MB(item.downlink.end.sum.bytes) }}
             </template>
         </td>
        </tr>
        <tr>
          <td colspan="2">Retransmits</td>
          <td colspan="1"
              style="text-align: right">
              <template
                      v-if="typeof item.uplink !== 'undefined' &&
                 typeof item.uplink.end.sum_sent !== 'undefined' &&
                 typeof item.uplink.end.sum_sent.retransmits !== 'undefined'"
              >
                  {{ item.uplink.end.sum_sent.retransmits }}
              </template>
              <template
                      v-else-if="typeof item.uplink !== 'undefined' &&
                                  protocol==='udp' &&
                                  typeof item.uplink.end.sum !== 'undefined' &&
                                  typeof item.uplink.end.sum.retransmits !== 'undefined'"

              >
                  {{ item.uplink.end.sum.retransmits }}
              </template>
          </td>
          <td   colspan="1"
                style="text-align: right">
              <template
                      v-if="typeof item.downlink !== 'undefined' &&
                  typeof item.downlink.end.sum_sent !== 'undefined' &&
                  typeof item.downlink.end.sum_sent.retransmits !== 'undefined'"

              >
                  {{ item.downlink.end.sum_sent.retransmits }}
              </template>
              <template
                      v-else-if="typeof item.downlink !== 'undefined' &&
                         protocol==='udp' &&
                         typeof item.downlink.end.sum!== 'undefined'  &&
                         typeof item.downlink.end.sum.retransmits!== 'undefined'"
              >
                  {{ item.downlink.end.sum.retransmits }}
              </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="red--text">
      <span
        v-if="typeof item.downlink !== 'undefined' &&
              typeof item.downlink.error !== 'undefined'"
        >Downlink: {{ item.downlink.error }}
      </span>
      <br>
      <span
        v-if="typeof item.uplink !== 'undefined' &&
              typeof item.uplink.error !== 'undefined'"
        >Uplink: {{ item.uplink.error }}
      </span>
    </div>
  </div>
</template>
<script>
import { convertMBits, convertByte2MB } from "@/js/helper.js";

export default {
  props: {
    item: Object,
    protocol:String
  },
  methods: {
    convertByte2MB(value) {
      return convertByte2MB(value);
    },
    convertMBits(value) {
      return convertMBits(value);
    },
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}
table td {
  border: thin solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
table th {
  border: thin solid rgba(255, 255, 255);
  padding: 5px;
}
.coloredBack,
.coloredBack:hover {
  background-color: #deebff;
}
</style>