<template>
  <div class="mb-2">
    <v-row no-gutters v-if="message">
      <v-col
          cols="10"
          style="text-align: center;"
      >
        <v-card
            class="pa-2 primary "
            outlined
            tile
            style="border-right-color: white !important;"
        >
          <span style="color: white;">{{ languagePack("sipCallTable", "msg") }} </span>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="message" no-gutters style="border-left: 1px solid #dbdbdb;max-width: 83.2%;">
      <v-col
          cols="12"
      >
        <v-card
            class="pa-2  "
            outlined
            tile
            style="background-color: transparent;border: none;border-right: 1px solid #dbdbdb;"
        >
          {{ message }}
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
          cols="10"
          style="text-align: center;"
      >
        <v-card
            class="pa-2 primary "
            outlined
            tile
        >
          <span style="color: white;">Stats</span>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col
          cols="10"
      >
        <v-card
            class="pa-2"
            outlined
            tile
            style="background-color: transparent"
        >
          <template v-for="(value,index) in stats">
             <v-row :key="index" no-gutters >
            <v-col
                cols="8"
            > {{ translatedValue(index) }}
            </v-col>
            <v-col
                cols="3"
                class="ml-3"
            >  {{ convertUnits(value,index) }}
            </v-col>
          </v-row>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { convertMs } from "@/js/helper.js";

export default {
  props: {
    item:  Object,
    sipCallAttr: Array,
    sipCallAttrUnits:Array
  },
  computed: {
    stats(){
       let stats = "";
       if(typeof this.item.stats!=='undefined'){
         stats =  this.item.stats;
       }
       return stats;
    },
    message(){
      let message = "";
      if(typeof this.item.msg!=='undefined'){
        message =  this.item.msg;
      }
      return message;
    }
  },
  methods: {
    convertUnits(value,index){
      let result = value;
      if(this.translatedValueUnits(index)=='ns'){
         result = this.convertMs(value);
      }
      if(this.translatedValueUnits(index)=='percent'){
        result = value+" "+"%";
      }
      if(this.translatedValueUnits(index)=='ms'){
        result = value+" "+"ms";
      }
      return result;
    },
    convertMs(value){
      return  convertMs(value);
    },
    translatedValue(input){
      let value = input
       if(input!='' && typeof this.sipCallAttr.find(a => a[0] == input)[1]!=='undefined'){
         value = this.sipCallAttr.find(a => a[0] == input)[1];
      }
      return value;
    },
    translatedValueUnits(input){
      let value = input
      if(input!='' && typeof this.sipCallAttrUnits.find(a => a[0] == input)[1]!=='undefined'){
        value = this.sipCallAttrUnits.find(a => a[0] == input)[1];
      }
      return value;
    }
  }
};
</script>