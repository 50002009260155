var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2"},[((typeof _vm.item.uplink !== 'undefined' &&
           Object.keys(_vm.item.uplink).length &&
           typeof _vm.item.uplink.error === 'undefined') ||
          (typeof _vm.item.downlink !== 'undefined' &&
           Object.keys(_vm.item.downlink).length &&
           typeof _vm.item.downlink.error === 'undefined'))?_c('table',{staticClass:"innerClassTable",staticStyle:{"width":"100%","max-width":"360px"}},[_c('colgroup',[_c('col',{staticStyle:{"width":"11.9101%"}}),_c('col',{staticStyle:{"width":"25.3805%"}}),(_vm.item.uplinkStatus)?_c('col',{staticStyle:{"width":"10%"}}):_vm._e(),(_vm.item.downlinkStatus)?_c('col',{staticStyle:{"width":"10%"}}):_vm._e()]),_c('tbody',[_c('tr',{staticClass:"primary white--text"},[_vm._m(0),(typeof _vm.item.uplink !== 'undefined')?_c('th',[_c('span',[_vm._v("Uplink")])]):_vm._e(),(typeof _vm.item.downlink !== 'undefined')?_c('th',[_c('span',[_vm._v("Downlink")])]):_vm._e()]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Bitrate")]),_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":"1"}},[(typeof _vm.item.uplink !== 'undefined' &&
                           typeof _vm.item.uplink.end.sum_sent !== 'undefined' &&
                           typeof _vm.item.uplink.end.sum_sent.bits_per_second !== 'undefined')?[_vm._v(" "+_vm._s(_vm.convertMBits(_vm.item.uplink.end.sum_sent.bits_per_second))+" ")]:(typeof _vm.item.uplink !== 'undefined' &&
                               _vm.protocol==='udp' &&
                               typeof _vm.item.uplink.end.sum !== 'undefined' &&
                               typeof _vm.item.uplink.end.sum.bits_per_second !== 'undefined')?[_vm._v(" "+_vm._s(_vm.convertMBits(_vm.item.uplink.end.sum.bits_per_second))+" ")]:_vm._e()],2),_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":"1"}},[(typeof _vm.item.downlink !== 'undefined' &&
                typeof _vm.item.downlink.end.sum_received !== 'undefined' &&
                typeof _vm.item.downlink.end.sum_received.bits_per_second !== 'undefined'   )?[_vm._v(" "+_vm._s(_vm.convertMBits(_vm.item.downlink.end.sum_received.bits_per_second))+" ")]:(typeof _vm.item.downlink !== 'undefined' &&
                     _vm.protocol==='udp' &&
                     typeof _vm.item.downlink.end.sum !== 'undefined' &&
                     typeof _vm.item.downlink.end.sum.bits_per_second !== 'undefined')?[_vm._v(" "+_vm._s(_vm.convertMBits(_vm.item.downlink.end.sum.bits_per_second))+" ")]:_vm._e()],2)]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Transfer")]),_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":"1"}},[(typeof _vm.item.uplink !== 'undefined' &&
                typeof _vm.item.uplink.end.sum_sent !== 'undefined' &&
                typeof _vm.item.uplink.end.sum_sent.bytes !== 'undefined')?[_vm._v(" "+_vm._s(_vm.convertByte2MB(_vm.item.uplink.end.sum_sent.bytes))+" ")]:(typeof _vm.item.uplink !== 'undefined' &&
                       _vm.protocol==='udp' &&
                       typeof _vm.item.uplink.end.sum !== 'undefined' &&
                       typeof _vm.item.uplink.end.sum.bytes !== 'undefined')?[_vm._v(" "+_vm._s(_vm.convertByte2MB(_vm.item.uplink.end.sum.bytes))+" ")]:_vm._e()],2),_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":"1"}},[(typeof _vm.item.downlink !== 'undefined' &&
                typeof _vm.item.downlink.end.sum_received !== 'undefined' &&
                typeof _vm.item.downlink.end.sum_received.bytes !== 'undefined')?[_vm._v(" "+_vm._s(_vm.convertByte2MB(_vm.item.downlink.end.sum_received.bytes))+" ")]:(typeof _vm.item.downlink !== 'undefined' &&
                       _vm.protocol==='udp' &&
                       typeof _vm.item.downlink.end.sum!== 'undefined' &&
                       typeof _vm.item.downlink.end.sum.bytes !== 'undefined')?[_vm._v(" "+_vm._s(_vm.convertByte2MB(_vm.item.downlink.end.sum.bytes))+" ")]:_vm._e()],2)]),_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v("Retransmits")]),_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":"1"}},[(typeof _vm.item.uplink !== 'undefined' &&
               typeof _vm.item.uplink.end.sum_sent !== 'undefined' &&
               typeof _vm.item.uplink.end.sum_sent.retransmits !== 'undefined')?[_vm._v(" "+_vm._s(_vm.item.uplink.end.sum_sent.retransmits)+" ")]:(typeof _vm.item.uplink !== 'undefined' &&
                                _vm.protocol==='udp' &&
                                typeof _vm.item.uplink.end.sum !== 'undefined' &&
                                typeof _vm.item.uplink.end.sum.retransmits !== 'undefined')?[_vm._v(" "+_vm._s(_vm.item.uplink.end.sum.retransmits)+" ")]:_vm._e()],2),_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":"1"}},[(typeof _vm.item.downlink !== 'undefined' &&
                typeof _vm.item.downlink.end.sum_sent !== 'undefined' &&
                typeof _vm.item.downlink.end.sum_sent.retransmits !== 'undefined')?[_vm._v(" "+_vm._s(_vm.item.downlink.end.sum_sent.retransmits)+" ")]:(typeof _vm.item.downlink !== 'undefined' &&
                       _vm.protocol==='udp' &&
                       typeof _vm.item.downlink.end.sum!== 'undefined'  &&
                       typeof _vm.item.downlink.end.sum.retransmits!== 'undefined')?[_vm._v(" "+_vm._s(_vm.item.downlink.end.sum.retransmits)+" ")]:_vm._e()],2)])])]):_c('div',{staticClass:"red--text"},[(typeof _vm.item.downlink !== 'undefined' &&
            typeof _vm.item.downlink.error !== 'undefined')?_c('span',[_vm._v("Downlink: "+_vm._s(_vm.item.downlink.error)+" ")]):_vm._e(),_c('br'),(typeof _vm.item.uplink !== 'undefined' &&
            typeof _vm.item.uplink.error !== 'undefined')?_c('span',[_vm._v("Uplink: "+_vm._s(_vm.item.uplink.error)+" ")]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"colspan":"2"}},[_c('br')])
}]

export { render, staticRenderFns }