<template>
  <div class="mb-2 pa-3" v-if="Object.keys(newItems).length!==0">
    <div style="width: 100%; max-width: 500px">
      <v-row class="primary white--text">
        <template v-for="(value,index) in newItems">
          <v-col
              v-if="noNeed(index)"
              :key="index"
              class="pa-1 table-index-column text-center"
              style=""
          > {{ languagePack("twampTable", index) }}
          </v-col>
        </template>
      </v-row>
      <v-row style="border: solid 1px #dbdbdb">
        <template v-for="(value,index) in newItems">
          <v-col
              v-if="noNeed(index)"
              :key="index"
              class="pa-1 table-value-column text-center"
          >
            {{ value }}
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>
<script>
import {objectCustomSort, convertMs} from "@/js/helper.js";

export default {
  data: function () {
    return {
      hideList: ['target', 'tos']
    }
  },
  props: {
    item: Object,
  },
  computed: {
    newItems() {
      var sortOrder = ['ipVersion', 'count', 'ttl', 'packet', 'interval', 'target'];
      return objectCustomSort(sortOrder, this.item);
    },

  },
  methods: {
    noNeed(index) {
      return !this.hideList.includes(index);
    },
    convertMs(value) {
      return convertMs(value);
    },
  }
};
</script>
<style scoped>
.table-index-column:not(:last-child) {
  border-right: thin solid white;
}

.table-value-column:not(:last-child) {
  border-right: thin solid #dbdbdb;
}
</style>