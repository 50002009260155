<template>
  <div class="mb-2">
    <table class="innerClassTable" style="width: 100%; max-width: 450px">
      <tbody>
      <tr class="primary white--text">
        <template v-for="(value,index) in newItems">
          <th v-if="index!='host'  " :key="index" class="primary">
            <span style="width: fit-content;white-space: nowrap;"> {{ languagePack("iperfTable", index) }}</span>
          </th>
        </template>
      </tr>
      <tr>
        <template v-for="(value,index) in newItems">
          <td v-if="index!='host'   " style="text-align: center;white-space: nowrap;"   :key="index">
             <span v-if="checkType(index,'time')">{{ value }} s</span>
             <span v-else-if="checkType(index,'volume')">{{ convertVol(value) }} </span>
             <span v-else>{{value }} </span>
          </td>
        </template>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
    import { objectCustomSort,convertMs,convertByte2MB } from "@/js/helper.js";
    export default {
        props: {
            item:  Object,
        },
        computed: {
            newItems(){
                var sortOrder = ['ipVersion','protocol','port','timeVol','timeVolValue','uplink','downlink','parallel','omit','host'];
                return objectCustomSort(sortOrder,this.item);
            },

        },
        methods: {
          checkType(value,type){
                var items = this.item;
                let answer = false;
                var found = Object.keys(items).filter(function(key) {
                    return items[key] === type;
                });
                if(typeof this.item[value]!="undefined" &&  value=="timeVolValue" && found.length>0){
                    answer = true;
                }
                return answer;
            },
            convertVol(value){
                return convertByte2MB(value);
            },
            convertMs(value){
                return convertMs(value);
            },
        }
    };
</script>
<style scoped>
  table {
    border-collapse: collapse;
  }
  table td {
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 5px;
  }
  table th {
    border: thin solid rgba(255, 255, 255);
    padding: 5px;
  }
  table th:nth-child(1), th:nth-child(2) ,th:nth-child(3),th:nth-child(4),th:nth-child(5),th:nth-child(6){
      border-right-color: white !important;
  }
  .coloredBack,
  .coloredBack:hover {
    background-color: #deebff;
  }
</style>