<template>
  <div class="mb-2">
    <table class="innerClassTable" style="width: 100%; max-width: 450px">
      <tbody>
        <tr class="primary white--text">
          <template v-for="(value, index) in newItems">
            <th
              v-if="index != 'host' && index != 'ipv4' && index != 'ipv6'"
              :key="index"
              class="primary"
            >
              <span style="white-space: nowrap">
                {{ languagePack("udpstTable", index) }}</span
              >
            </th>
          </template>
        </tr>
        <tr>
          <template v-for="(value, index) in newItems">
            <td
              v-if="index != 'host' && index != 'ipv4' && index != 'ipv6'"
              style="text-align: center; white-space: nowrap"
              :key="index"
            >
              <span v-if="index == 'time'">{{ value }} s</span>
              <span
                v-else-if="index == 'upperDelayVar' || index == 'lowDelayVar'"
                >{{ value }} ms</span
              >
              <span v-else>{{ value }}</span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { objectCustomSort } from "@/js/helper.js";
export default {
  props: {
    item: Object,
  },
  computed: {
    newItems() {
      // console.log("--->",this.item);
      var sortOrder = [
        "ipVersion",
        "protocol",
        "port",
        "time",
        "uplink",
        "downlink",
        "lowDelayVar",
        "upperDelayVar",
        "c",
        "h",
        "host",
        "ipv4",
        "ipv6",
      ];
      return objectCustomSort(sortOrder, this.item);
    },
  },
  /*    methods: {
            convertMs(value){
                return convertMs(value);
            },
        }*/
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}
table td {
  border: thin solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
table th {
  border: thin solid rgba(255, 255, 255);
  padding: 5px;
}
table th:nth-child(1), th:nth-child(2) ,th:nth-child(3),th:nth-child(4),th:nth-child(5),th:nth-child(6),th:nth-child(7),th:nth-child(8){
    border-right-color: white !important;
}
.coloredBack,
.coloredBack:hover {
  background-color: #deebff;
}
</style>