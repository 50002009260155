<template>
  <div class="mb-2">
    <table class="innerClassTable" style="width: 100%; max-width: 440px">
      <tbody>
        <tr class="primary white--text">
          <template v-for="(value, index) in newItems">
            <th v-if="index != 'server'" :key="index" class="primary" >
              <span> {{ languagePack("dnsTable", index) }}</span>
            </th>
          </template>
        </tr>
        <tr>
          <template v-for="(value, index) in newItems">
            <td
              v-if="index != 'server'"
              style="text-align: center; white-space: nowrap"
              :key="index"
            >
              <span>{{ value }}</span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { objectCustomSort, convertMs } from "@/js/helper.js";
export default {
  props: {
    item: Object,
  },
  computed: {
    newItems() {
      var sortOrder = ["ipVersion", "port", "domain", "type", "server",'useDefaultDNS'];
      return objectCustomSort(sortOrder, this.item);
    },
  },
  methods: {
    convertMs(value) {
      return convertMs(value);
    },
  },
};
</script>
<style scoped>
    table {
        border-collapse: collapse;
    }
    table td {
        border: thin solid rgba(0, 0, 0, 0.12);
        padding: 5px !important;
    }
    table th {
        border: thin solid white!important;
        padding: 5px !important;
    }
    table th:nth-child(1), th:nth-child(2), th:nth-child(3) {
        border-right-color: white !important;
    }

    .coloredBack,
    .coloredBack:hover {
        background-color: #deebff;
    }
</style>