<template>
  <div class="mb-2 pa-3" v-if="Object.keys(newItems).length!==0">
    <div style="width: 100%; max-width: 500px">
      <v-row class="primary white--text">
        <template v-for="(value,index) in newItems">
          <v-col
              v-if="noNeed(index)"
              :key="index"
              class="pa-1 table-index-column text-center"
              style=""
          > {{ languagePack("twampTable", index) }}
          </v-col>
        </template>
      </v-row>
      <v-row style="border: solid 1px #dbdbdb">
        <template v-for="(value,index) in newItems">
          <v-col
              v-if="noNeed(index)"
              :key="index"
              class="pa-1 table-value-column text-center"
          >
            <span v-if="index=='lost'">{{ value }} % </span>
            <span v-else-if="index=='errorCode'">{{ value }}</span>
            <span v-else>{{ convertMs(value) }}</span>
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>
<script>
import {objectCustomSort, convertMs} from "@/js/helper.js";

export default {
  data: function () {
    return {
      hideList: ['Result']
    }
  },
  props: {
    item: Object,
  },
  computed: {
    newItems() {
     // console.log(this.item);
      let itemList = {};
      var sortOrder = ['lost', 'avg', 'min', 'max', 'mdev'];
       if (typeof this.item.Result['successful'] !== 'undefined' && this.item.Result['successful'] === false) {
        sortOrder = ['errorCode'];
        itemList['errorCode'] = typeof this.item.Result['errorCode'] !== 'undefined' ? this.item.Result['errorCode'] : ""
      }else{
         itemList = this.item;
       }
      //console.log(itemList);
      return objectCustomSort(sortOrder,itemList);
    }
  },
  methods: {
    convertMs(value) {
      return convertMs(value);
    },
    noNeed(index) {
      return !this.hideList.includes(index);
    },
  }
};
</script>
<style scoped>
.table-index-column:not(:last-child) {
  border-right: thin solid white;
}

.table-value-column:not(:last-child) {
  border-right: thin solid #dbdbdb;
}
</style>