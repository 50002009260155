<template>
  <div class="mb-2 pa-3" v-if="Object.keys(newItems).length!==0">
    <div  :style="{ 'max-width' : tableWidth+'px' }"   >
      <v-row class="primary white--text">
        <template v-for="(value,index) in newItems">
          <v-col
              :key="index"
              class="pa-1 table-index-column text-center"
              style=""
          > {{ languagePack("basicModemTable", index) }}
          </v-col>
        </template>
      </v-row>
      <v-row style="border: solid 1px #dbdbdb">
        <template v-for="(value,index) in newItems">
          <template v-if="typeof value==='object'">
            <v-col :key="index">
              <template v-for="(innerValue,innerIndex) in value">
                <template v-if="innerValue!=''">
                  <v-row
                      :key="innerIndex"
                      class="pa-1"
                  >
                    <v-col
                        class="pa-1 "
                        cols="6"
                        :class="textAlignClass"
                    >
                      {{ innerIndex }}
                    </v-col>
                    <v-col
                        class="pa-1 "
                        cols="6"
                        :class="textAlignClass"
                    >
                      {{ innerValue }}
                    </v-col>
                  </v-row>
                </template>
              </template>
            </v-col>
          </template>
          <template v-else>
            <v-col
                :key="index"
                class="pa-1 table-value-column text-center"
            > {{ value }}
            </v-col>
          </template>
        </template>
      </v-row>
    </div>
  </div>
</template>
<script>
import {objectCustomSort, convertMs} from "@/js/helper.js";

export default {
  props: {
    item: Object,
    type: String
  },
  data() {
    return {
     tableWidth:"700",
     textAlignClass:""
    };
  },
  computed: {
    newItems() {
      this.customTextAlign(this.type);
      this.customWidth(this.type);
     // console.log(this.item);
      let sortOrder = [];
      let itemList = {};
      if (this.type == 'Explorer_SMS') {
        sortOrder = ['successful', 'errorCode'];
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          itemList['delivery_state'] = typeof this.item['delivery_state'] !== 'undefined' ? this.item['delivery_state'] : ""
        }
        //itemList['successful'] = typeof this.item.result['successful']!=='undefined' ? this.item.result['successful']:""
        if (typeof this.item.result['message'] !== 'undefined') {
          delete this.item.result['message'];
        }
      } else if (this.type == 'Explorer_cellular_voice_call') {
        sortOrder = ['successful', 'errorCode'];
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          itemList['stats'] = typeof this.item['stats'] !== 'undefined' ? this.item['stats'] : ""
        }
      } else if (this.type == 'Explorer_cellular_attach') {
        sortOrder = ['successful', 'errorCode'];
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else if (typeof this.item.register_info !== 'undefined' && this.item.register_info != '') {
          itemList['registerInfoState'] = (typeof this.item.register_info['state'] !== 'undefined') ? this.item.register_info['state'] : ""
          itemList['registerInfoStateNo'] = (typeof this.item.register_info['stateNo'] !== 'undefined') ? this.item.register_info['stateNo'] : ""
        }
        //itemList['successful'] = typeof this.item.result['successful']!=='undefined' ? this.item.result['successful']:""
      } else if (this.type == 'Explorer_cellular_data_connect') {
        sortOrder = ['successful', 'errorCode'];
        if (typeof this.item.result['successful'] !== 'undefined' && this.item.result['successful'] === false) {
          itemList['errorCode'] = typeof this.item.result['errorCode'] !== 'undefined' ? this.item.result['errorCode'] : ""
        } else {
          itemList['ipv4'] = typeof this.item.ipv4 !== 'undefined' ? this.item.ipv4 : ""
          itemList['ipv6'] = typeof this.item.ipv6 !== 'undefined' ? this.item.ipv6 : ""
        }
        // itemList['successful'] = typeof this.item.result['successful']!=='undefined' ? this.item.result['successful']:""

      }else if (this.type == 'Explorer_cellular_data_disconnect') {

        itemList['statistics'] = typeof this.item['statistics'] !== 'undefined' ? this.item['statistics'] : ""
      }
      else {
        itemList = this.item;
      }

      return objectCustomSort(sortOrder, itemList);
    }
  },
  methods: {
    customTextAlign(type){
      if(type=='Explorer_cellular_data_disconnect') {
        this.textAlignClass="text-center";
      }
    },
    customWidth(type){
      if(type=='Explorer_cellular_data_disconnect' || type=='Explorer_cellular_attach') {
        this.tableWidth="300";
      }
    },
    convertMs(value) {
      return convertMs(value);
    },
  }
};
</script>
<style scoped>
.table-index-column:not(:last-child) {
  border-right: thin solid white;
}

.table-value-column:not(:last-child) {
  border-right: thin solid #dbdbdb;
}

</style>