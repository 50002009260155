<template>
    <div class="mb-2">
        <table class="innerClassTable" style="width: 100%; max-width: 450px">
            <tbody>
            <tr class="primary white--text">
                <template v-for="(value,index) in newItems">
                    <th v-if="index!='target'" :key="index" class="primary">
                        <span style="white-space: nowrap;"> {{ languagePack("pingTable", index) }}</span>
                    </th>
                </template>
            </tr>
            <tr>
                <template v-for="(value,index) in newItems">
                    <td v-if="index!='target'" style="text-align: center;white-space: nowrap;"   :key="index">
                        <span>{{ value }}</span>
                    </td>
                </template>
            </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
  import { objectCustomSort,convertMs } from "@/js/helper.js";
  export default {
    props: {
     item:  Object,
    },
    computed: {
      newItems(){
        //console.log("--->",this.item);//{"target":"192.168.1.1","packet":56,"ipVersion":"ipv4","ttl":64,"count":4}
        var sortOrder = ['ipVersion','count','ttl','packet','interval','target','tos','df'];
        return objectCustomSort(sortOrder,this.item);
      }
    },
    methods: {
      convertMs(value){
        return convertMs(value);
      },
    }
  };
</script>
<style scoped>
    table {
        border-collapse: collapse;
    }
    table td {
        border: thin solid rgba(0, 0, 0, 0.12);
        padding: 5px;
    }
    table th {
        border: thin solid white;
        padding: 5px;
    }
    table th:nth-child(1), th:nth-child(2), th:nth-child(3) {
        border-right-color: white !important;
    }
    .coloredBack,
    .coloredBack:hover {
        background-color: #deebff;
    }
</style>