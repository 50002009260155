<template>
    <div class="mb-2">
      <table class="innerClassTable" style="width: 100%; max-width: 750px;line-height: 30px">
            <tbody>
            <tr class="primary white--text">
                <template v-for="(value,index) in title">
                    <th :key="index" class="primary">
                        <span style="white-space: nowrap;"> {{ languagePack("rttCheckTable", value) }}</span>
                    </th>
                </template>
            </tr>
            <tr v-for="(value,index) in newItems"  :key="index" style="text-align: right;">
                       <td>
                           <div v-for="(hostValue,hostIndex) in value['hostname']"  :key="hostIndex" style="white-space: nowrap;">
                               {{hostValue}}
                           </div>
                       </td>
                       <td>
                           {{value['ip']}}
                       </td>
                       <template v-if="value['dnsServers']!=null">
                          <td>
                           <div v-for="(dnsValue,dnsIndex) in value['dnsServers']"  :key="dnsIndex" style="white-space: nowrap;">
                               {{dnsValue.ip}}<span v-if="typeof dnsValue.default && dnsValue.default"> *</span>
                           </div>
                          </td>
                       </template>
                       <template v-else>
                           <td></td>
                       </template>
                       <template v-if="value['measurement']!=null">
                            <template v-for="(measurementValue,measurementIndex) in measurementSort(value['measurement'])">
                                <td :key="measurementIndex" style="white-space: nowrap;">
                                    <template v-if="measurementIndex=='lost'">
                                        {{measurementValue}} %
                                    </template>
                                    <template v-else>
                                        {{convertMs(measurementValue)}}
                                    </template>
                                </td>
                             </template>
                       </template>
                    </tr>
            </tbody>
        </table>
        <div class="caption">DNS servers marked with an * are configured locally on the testing system</div>
    </div>
</template>
<script>
   import { objectCustomSort } from "@/js/helper.js";
   import { convertMs } from "@/js/helper.js";
  export default {
    props: {
      item:  Array,
    },
    computed: {
      title(){
        return ['hostname','ip','dnsServers','lost','avg','min','max','mdev'];
      },
      newItems(){
        this.item.filter(function(el) { //remove eval
           if(typeof el.eval!='undefined'){
             delete el.eval;
           }
            return el;
        });
         return this.item;
      }
    },
    methods: {
      convertMs(value){
        return convertMs(value);
      },
      measurementSort(value){
        let order = ['lost','avg','min','max','mdev'];
        return objectCustomSort(order,value);
      }
    }
  };
</script>
<style scoped>
    table {
        border-collapse: collapse;
    }
    table td {
        border: thin solid rgba(0, 0, 0, 0.12);
        padding: 5px;
    }
    table th {
        border: thin solid rgba(255, 255, 255);
        padding: 5px;
    }
    table th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6), th:nth-child(7) {
        border-right-color: white !important;
    }
    .coloredBack,
    .coloredBack:hover {
        background-color: #deebff;
    }
</style>