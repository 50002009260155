<template>
  <div class="mb-2">
    <table style="width: 100%; max-width: 600px" class="innerClassTable">
      <thead>
        <tr class=" white--text">
          <th style="text-align: center;border-bottom-color: white!important;" class="primary font-weight-bold" colspan="3">
            Path
          </th>
        </tr>
        <tr class="primary white--text">
          <td style="text-align: center;border-bottom-color: white!important;border-right-color: white!important;" class="primary font-weight-bold" colspan="2">
            Hop
          </td>
          <td style="text-align: center;border-bottom-color: white!important;" class="primary font-weight-bold">RTT</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(hop, index) in item.hops" :key="index">
          <td>{{ hop.ttl }}</td>
          <td>{{ hop.host }}</td>
          <td>{{ toms(hop.elapsedTime) }}</td>
        </tr>

        <tr class="primary white--text">
          <td colspan="3" style="text-align: center" class="primary font-weight-bold">
            Summary
          </td>
        </tr>
        <tr>
          <td colspan="2">No. of Hops</td>
          <td>{{ summary.hops }}</td>
        </tr>
        <tr>
          <td colspan="2">Min RTT</td>
          <td>{{ toms(summary.minRtt) }}</td>
        </tr>
        <tr>
          <td colspan="2">Max RTT</td>
          <td>{{ toms(summary.maxRtt) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    item:  [Object,String],
  },
  computed: {
    summary() {
      let summary = {};
      if (typeof this.item.summary != "undefined") {
        summary = this.item.summary;
      }
      return summary;
    },
  },
  methods: {
    toms(value) {
      if(typeof value!="undefined"){
        return (value / 1000000).toFixed(2) + " ms";
      }
    },
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}
table td {
  border: thin solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
table th {
  border: thin solid rgba(255, 255, 255);
  padding: 5px;
}

.coloredBack,
.coloredBack:hover {
  background-color: #deebff;
}
</style>