<template>
  <div class="mb-2 pa-3">
    <div  :style="{ 'max-width' : tableWidth+'px' }" >
      <v-row class="primary white--text">
        <template v-for="(value,index) in newItems">
          <v-col
              :key="index"
              class="pa-1 table-index-column text-center"
              style=""
          > {{ languagePack("basicModemTable", index) }}
          </v-col>
        </template>
      </v-row>
      <v-row style="border: solid 1px #dbdbdb">
        <template v-for="(value,index) in newItems">
          <v-col
              :key="index"
              class="pa-1 table-value-column text-center"
          > {{ value }}
          </v-col>
        </template>
      </v-row>
    </div>
  </div>
</template>
<script>
import {objectCustomSort, convertMs} from "@/js/helper.js";

export default {
  props: {
    item: Object,
    type: String
  },
  data() {
    return {
      tableWidth:"700"
    };
  },
  computed: {
    newItems() {
      var sortOrder = [];
      let itemList = {};
      if (this.type == "Explorer_USSD") {
        sortOrder = ['code', 'timeout'];
        itemList = this.item;
      } else if (this.type == 'Explorer_SMS') {
        sortOrder = ['targetNumber', 'smsc', 'text', 'statusRequest'];
        if (typeof this.item['statusRequest'] !== 'undefined' && this.item['statusRequest'].length == 0) {
          delete this.item['statusRequest'];
        }
        itemList = this.item;
      } else if (this.type == 'Explorer_cellular_voice_call') {
        sortOrder = ['type', 'targetNumber', 'dialingTime', 'connectionTime'];
        itemList = this.item;
      } else if (this.type == 'Explorer_cellular_attach') {
        sortOrder = ['ratType', 'network', 'simType', 'slot'];
        itemList['ratType'] = typeof this.item.radio['ratType'] !== 'undefined' ? this.item.radio['ratType'] : ""
        itemList['network'] = typeof this.item['network'] !== 'undefined' ? this.item['network'] : ""
        itemList['simType'] = typeof this.item.sim['simType'] !== 'undefined' ? this.item.sim['simType'] : ""
        itemList['slot'] = typeof this.item.sim['slot'] !== 'undefined' ? this.item.sim['slot'] : ""
      } else if (this.type == 'Explorer_cellular_data_connect') {
        sortOrder = ['apn', 'subscriber', 'user', 'password'];
        if (typeof this.item['user'] !== 'undefined' && this.item['user'].length == 0) {
          delete this.item['user'];
        }
        if (typeof this.item['password'] !== 'undefined' && this.item['password'].length == 0) {
          delete this.item['password'];
        }
        if(typeof this.item['subscriber']!=='undefined'){
          delete this.item['subscriber'];
        }
        if (typeof this.item['msisdn'] !== 'undefined') {
          delete this.item['msisdn'];
        }
        itemList = this.item;
      } else {
        itemList = this.item;
      }
      return objectCustomSort(sortOrder, itemList);
    }
  },
  methods: {
    /* customWidth(type){
       if(type=='Explorer_cellular_data_disconnect' || type=='Explorer_cellular_attach') {
          this.tableWidth="300";
        }
    },*/
    convertMs(value) {
      return convertMs(value);
    },
  }
};
</script>
<style scoped>
.table-index-column:not(:last-child) {
  border-right: thin solid white;
}

.table-value-column:not(:last-child) {
  border-right: thin solid #dbdbdb;
}

</style>