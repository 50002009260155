<template>
  <div class="mb-2">
    <v-row no-gutters style="border-right: 1px solid #dbdbdb; ">
      <v-col
          cols="2"
          style="text-align: center;"
      >
        <v-card
            class="pa-2 primary"
            outlined
            tile
            style="border-right-color: white !important;border-right: 1px solid white!important;"
        >
          <span style="color: white;">{{ languagePack("sipCallTable", "duration") }}</span>
        </v-card>
      </v-col>
      <v-col
          cols="6"
          style="text-align: center;"
      >
        <v-card
            class="pa-2 primary"
            outlined
            tile
            style="border-right-color: white !important;"
        >
          <span style="color: white;">{{ languagePack("sipCallTable", "sipAccount") }}</span>
        </v-card>
      </v-col>
      <v-col
          cols="4"
          style="text-align: center;"
      >
        <v-card
            class="pa-2 primary"
            outlined
            tile
        >
          <span style="color: white;">{{ languagePack("sipCallTable", "calledSipId") }}</span>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters style="border: 1px solid #dbdbdb; ">
      <v-col
          cols="2"
          style="text-align: center;border-right: 1px solid #dbdbdb"
      >

        <span >{{ duration }} Sec</span>

      </v-col>
      <v-col
          cols="6"
          style="text-align: center;border-right: 1px solid #dbdbdb"

      >
        <div><span class="subtitle-2">SIP ID:</span> {{ sipAccount.id }}</div>
        <div class="ml-n4"><span class="subtitle-2">SIP Registrar:</span> {{sipAccount.registrar}}</div>

      </v-col>
      <v-col
          cols="4"
          style="text-align: center;"
      >
        <span>{{ calledSipId }}</span>
      </v-col>
    </v-row>
  </div>
</template>
<script>
//import { objectCustomSort } from "@/js/helper.js";
export default {
  props: {
    item: Object,
  },
  computed: {
/*    newItems() {
      // console.log("--->",this.item);
      var source = this.item;
      var sortOrder = [
        "sipAccount",
        "calledSipId",
      ];
      if(source["codecs"]) {
        delete source["codecs"];
      }
      return objectCustomSort(sortOrder, source);
    },*/
    duration(){
      let duration = "";
      if(typeof this.item.duration!=='undefined'){
        duration =  this.item.duration;
      }
      return duration;
    },
    sipAccount(){
      let sipAccount = "";
      if(typeof this.item.sipAccount!=='undefined'){
        sipAccount =  this.item.sipAccount;
      }
      return sipAccount;
    },
    calledSipId(){
      let calledSipId = "";
      if(typeof this.item.calledSipId!=='undefined'){
        calledSipId =  this.item.calledSipId;
      }
      return calledSipId;
    }
  },
  /*    methods: {
            convertMs(value){
                return convertMs(value);
            },
        }*/
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}
table td {
  border: thin solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
table th {
  border: thin solid rgba(255, 255, 255);
  padding: 5px;
}
table th:nth-child(1), th:nth-child(2) ,th:nth-child(3),th:nth-child(4),th:nth-child(5),th:nth-child(6),th:nth-child(7),th:nth-child(8){
    border-right-color: white !important;
}
.coloredBack,
.coloredBack:hover {
  background-color: #deebff;
}
</style>