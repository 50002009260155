<template>
  <div>
    <template v-if="row.rawType === 'Explorer_Traceroute'">
      <template
          v-if=" row.signDirection === 'out' && row.c_packet_out_show_raw != null && Object.keys(row.c_packet_out_show_raw).length > 0">
        <xtraceRouteRequestTable :item="traceRouteRequestData"></xtraceRouteRequestTable>
      </template>
      <template
          v-else-if="row.signDirection==='in' && row.c_packet_in_raw != null && Object.keys(row.c_packet_in_raw).length > 0">
        <xtraceRouteTable :item="row.c_packet_in_raw"></xtraceRouteTable>
      </template>
    </template>
    <template v-if="row.rawType === 'Explorer_BBM'">
      <template
          v-if=" row.signDirection === 'out'  &&  row.c_packet_out_show_raw!=null && Object.keys(row.c_packet_out_show).length > 0"></template>
      <template
          v-else-if="row.signDirection==='in' && row.c_packet_in_raw != null && Object.keys(row.c_packet_in_raw).length > 0">
        <xbbmTable :item="row.c_packet_in_raw"></xbbmTable>
      </template>
    </template>
    <template v-if="row.rawType === 'Explorer_Speedtest'">
      <template
          v-if="row.signDirection==='out' && row.c_packet_out_show_raw != null && Object.keys(row.c_packet_out_show).length > 0"></template>
      <template
          v-else-if="row.signDirection === 'in' && row.c_packet_in_raw != null && Object.keys(row.c_packet_in_raw).length > 0">
        <xspeedTestTable :item="row.c_packet_in_raw" ></xspeedTestTable>
      </template>
    </template>
    <template v-if="row.rawType === 'Explorer_librespeedtest'">
      <template
          v-if="row.signDirection === 'out' &&  row.c_packet_out_show_raw != null && Object.keys(row.c_packet_out_show_raw).length > 0">
        <xLibreSpeedTestRequestTable :item="row.c_packet_out_show_raw"></xLibreSpeedTestRequestTable>
      </template>
      <template
          v-else-if="row.signDirection === 'in' && row.c_packet_in_raw != null && Object.keys(row.c_packet_in_raw).length > 0">
        <xLibreSpeedTestTable :item="row.c_packet_in_raw"></xLibreSpeedTestTable>
      </template>
    </template>
    <template v-if="row.rawType === 'Explorer_UDPST'">
      <template
          v-if=" row.signDirection === 'out' && row.c_packet_out_show_raw != null && Object.keys(row.c_packet_out_show_raw).length > 0 ">
        <xudpstRequestTable :item="row.c_packet_out_show_raw"></xudpstRequestTable>
      </template>
      <template
          v-else-if="row.signDirection === 'in' && row.c_packet_in_raw != null && Object.keys(row.c_packet_in_raw).length > 0">
        <xudpstTable :item="row.c_packet_in_raw"></xudpstTable>
      </template>
    </template>
    <template v-if="row.rawType === 'Explorer_DNS' ">
      <template
          v-if=" row.signDirection === 'out'  &&  row.c_packet_out_show_raw!=null && Object.keys(row.c_packet_out_show_raw).length > 0 ">
        <xdnsRequestTable :item="row.c_packet_out_show_raw"></xdnsRequestTable>
      </template>
      <template
          v-else-if="row.signDirection === 'in' &&  row.c_packet_in_raw!=null && Object.keys(row.c_packet_in_raw).length > 0">
        <xdnsTable :item="row.c_packet_in_raw"></xdnsTable>
      </template>
    </template>
    <template v-if="row.rawType === 'Explorer_PING'">
      <template
          v-if=" row.signDirection === 'out' &&  row.c_packet_out_show_raw!=null && Object.keys(row.c_packet_out_show_raw).length > 0">
        <xpingRequestTable :item="pingRequestData"></xpingRequestTable>
      </template>
      <template
          v-else-if="row.signDirection === 'in' && row.c_packet_in_raw!=null && Object.keys(row.c_packet_in_raw).length > 0">
        <xpingTable :item="row.c_packet_in_raw"></xpingTable>
      </template>
    </template>
    <template v-if="row.rawType === 'Explorer_TWAMP'">
      <template
          v-if=" row.signDirection === 'out' &&  row.c_packet_out_show_raw!=null && Object.keys(row.c_packet_out_show_raw).length > 0">
        <xtwampRequestTable :item="this.row.c_packet_out_show_raw"></xtwampRequestTable>
      </template>
      <template
          v-else-if="row.signDirection === 'in' && row.c_packet_in_raw!=null && Object.keys(row.c_packet_in_raw).length > 0">
        <xtwampTable :item="row.c_packet_in_raw"></xtwampTable>
        <template v-if="typeof row.c_packet_in_raw.result!='undefined' && typeof row.c_packet_in_raw.result.message!='undefined'" >
          <div>
            {{row.c_packet_in_raw.result.message}}
          </div>
        </template>
      </template>
    </template>

    <template v-if="row.rawType === 'Explorer_cellular_voice_call' ||
                    row.rawType === 'Explorer_cellular_data_connect' ||
                    row.rawType === 'Explorer_USSD' ||
                    row.rawType === 'Explorer_SMS' ||
                    row.rawType === 'Explorer_cellular_attach' ||
                    row.rawType === 'Explorer_cellular_detach' ||
                    row.rawType === 'Explorer_cellular_data_disconnect' ||
                    row.rawType === 'Explorer_bind' ||
                    row.rawType === 'Explorer_unbind'
    ">
      <template
          v-if=" row.signDirection === 'out' &&
          row.c_packet_out_show_raw!=null &&
          Object.keys(row.c_packet_out_show_raw).length > 0">
        <xbasicModemRequestTable
            :item="this.row.c_packet_out_show_raw"
            :type="row.rawType"
        ></xbasicModemRequestTable>
      </template>
      <template
          v-else-if="row.signDirection === 'in' &&
                     row.c_packet_in_raw!=null &&
                     Object.keys(row.c_packet_in_raw).length > 0
                     ">
        <template v-if="row.rawType == 'Explorer_USSD' && typeof row.c_packet_in_raw.response!=='undefined'">
          <div>{{row.c_packet_in_raw.response}}</div>
        </template>
        <template v-else>
          <xbasicModemTable
              :item="row.c_packet_in_raw"
              :type="row.rawType"
          >
          </xbasicModemTable>
        </template>
      </template>
<!--      <template v-else>
         {{row.c_type}}
      </template>-->
    </template>
    <template v-if="row.rawType === 'Explorer_iperf' ">
      <template
          v-if=" row.signDirection === 'out' &&  row.c_packet_out_show_raw!=null && Object.keys(row.c_packet_out_show_raw).length > 0">
        <xiperfRequestTable :item="iperfRequestData"></xiperfRequestTable>
      </template>
      <template
          v-else-if="row.signDirection === 'in' &&  row.c_packet_in_raw != null && Object.keys(row.c_packet_in_raw).length > 0">
        <xiperfTable :item="row.c_packet_in_raw" :protocol="row.c_packet_out_show_raw.protocol"></xiperfTable>
      </template>
    </template>
    <template v-if="row.rawType === 'Explorer_rttCheck' ">
      <template
          v-if=" row.signDirection==='out' &&  row.c_packet_out_show_raw!=null && Object.keys(row.c_packet_out_show_raw).length > 0">
        <xrttCheckRequestTable :item="row.c_packet_out_show_raw"></xrttCheckRequestTable>
      </template>
      <template
          v-else-if="row.signDirection==='in' &&  row.c_packet_in_raw!=null && Object.keys(row.c_packet_in_raw).length > 0">
        <xrttCheckTable :item="row.c_packet_in_raw"></xrttCheckTable>
      </template>
    </template>
    <template v-if="row.rawType === 'Explorer_SIP_CALL'">
      <template
          v-if=" row.signDirection === 'out' && row.c_packet_out_show_raw != null && Object.keys(row.c_packet_out_show_raw).length > 0 ">
        <xsipCallRequestTable :item="row.c_packet_out_show_raw"></xsipCallRequestTable>
      </template>
      <template
          v-else-if="row.signDirection === 'in' && row.c_packet_in_raw != null && Object.keys(row.c_packet_in_raw).length > 0">
        <xsipCallTable
            :item="row.c_packet_in_raw"
            :sipCallAttr="result.additional.sipCallAttr"
            :sipCallAttrUnits="result.additional.sipCallAttrUnits"
        ></xsipCallTable>
      </template>
    </template>
    <!-- if else conditions-->
    <div v-if="row.rawType === 'WAIT_END'">
      <span v-if="typeof row['c_messages'].msg != 'undefined'">
        {{ row['c_messages'].msg }}
        <br/>
        <span v-if="typeof row['c_messages'].condition != 'undefined'">Condition: {{
            row['c_messages'].condition
          }}</span>
      </span>
    </div>
    <div v-else-if="row.rawType === 'STARTTEST'">
      <span v-if="typeof row['c_messages'].link != 'undefined'">
        {{ row['c_messages'].msg }}:
        <a :href="row['c_messages'].link" target="_blank" title="Click to open the testcase">{{
            row['c_messages'].name
          }}</a>
        (ID# {{ row['c_messages'].id }})
      </span>
    </div>
    <div v-else-if="row.rawType === 'Comments'">
      <div style="white-space: pre-line" v-html="commentMessage"></div>
    </div>
    <div v-else-if="row['c_type'] === 'Pause'">
      {{ row['c_messages'] }}
      <v-btn
          :disabled="!row['enabled']"
          :style="row['enabled'] === false ? 'cursor: not-allowed' : ''"
          class="mt-1 mb-1 ml-3"
          color="primary"
          height="25"
          width="95"
          @click="resumePauseAndReturnFalse">
        <v-icon>mdi-reiterate</v-icon>
        <span>{{ language.componentLabel.labelResume }}</span>
      </v-btn>
    </div>
    <div v-else-if="row.rawType === 'CALC'&& typeof row.c_messages['msg'] != 'undefined' ">
          <div style="">{{ row.c_messages['msg'] }}</div>
          <div v-for="(messageLine, givenKey) in row.c_messages" :key="givenKey">
              <template v-if="!empty(messageLine['value'])">
                  <div class="sectionHeader">Check:</div>
              </template>
              <template v-if="typeof messageLine['name']!='undefined' && messageLine['name']!==''">
                  <span class="ml-3" v-html="constructNameString(messageLine['name'])"></span>
              </template>
              <span :style="getStyle(messageLine)">{{ messageLine['value']}}</span>
          </div>
      </div>
    <template v-else>
      <template
          v-if="row.rawType.includes('Explorer_')  && !row.rawType.includes('Explorer_VF')  && typeof row.c_messages!='undefined' && row.c_messages!=null && Object.keys(row.c_messages).length>0">
         <span v-for="(messageLine, givenKey,indexParent) in row.c_messages" :key="indexParent">
           <template
               v-if="messageLine!=null && messageLine !== '' && givenKey === 'evalV2' && givenKey !== 'eval' && row.rawType !== 'Explorer_rttCheck'">
                <span v-for="(inMessageLine, inKey) in messageLine" :key="inKey">
                    <template v-if="inKey === 'uplink' || inKey === 'downlink'">
                      <span class="sectionHeader">Check:</span> {{ inKey.charAt(0).toUpperCase() + inKey.slice(1) }}<br/>
                        <span v-for="(deepMessageLine, deepKey) in inMessageLine" :key="deepKey" class="ml-3">
                           <template v-if="deepMessageLine['type']!=null">
                               {{ deepMessageLine['type'] }} ({{
                               getConvert(deepMessageLine['step_content'], deepMessageLine['type'])
                               }})<span class="rule">{{ deepMessageLine['operator'] }}</span> "{{ deepMessageLine['check_content'] }}": <span
                               :style="getColor(deepMessageLine['result'])">{{ getCheckResult(deepMessageLine['result']) }}</span><br/>
                           </template>
                        </span>
                     </template>
                     <template v-else>  <!--sip call coming -->
                       <span v-if="inKey===0" class="sectionHeader">Check:<br></span>
                         <template v-if="inMessageLine['type']!=null">
                           <template v-if="row.rawType=='Explorer_USSD'">
                              <span class="ml-3">{{  getMappedEvalType(inMessageLine['type']) }}
                                   <span class="rule">{{ inMessageLine['operator'] }}</span> "{{ inMessageLine['check_content'] }}": <span
                                    :style="getColor(inMessageLine['result'])"> {{ getCheckResult(inMessageLine['result']) }}</span>
                             </span>
                           </template>
                           <template v-else>
                              <span class="ml-3">{{  getMappedEvalType(inMessageLine['type'])}} ({{
                                  getConvert(inMessageLine['step_content'], inMessageLine['type'])
                                }}) <span class="rule">{{ inMessageLine['operator'] }}</span> "{{ inMessageLine['check_content'] }}": <span
                                    :style="getColor(inMessageLine['result'])"> {{ getCheckResult(inMessageLine['result']) }}</span>
                             </span>
                           </template>
                         </template>
                     </template>
                <br/>
                </span>
            </template>
           <template v-if="messageLine!=null && messageLine !== '' && row.rawType === 'Explorer_rttCheck'">
               <template v-if="typeof messageLine!=='string'">
                 <span v-for="(inMessageLine, inKey) in messageLine" :key="inKey">
                   <span v-if="!empty(inMessageLine)">
                     <span class="sectionHeader">Check:</span>
                     <template v-if="typeof row.c_packet_in_raw[inKey].hostname!=='undefined' && row.c_packet_in_raw[inKey].hostname!=null">
                   {{row.c_packet_in_raw[inKey].hostname[0] }}
               </template>
               <template v-if="typeof row.c_packet_in_raw[inKey].ip!=='undefined' && row.c_packet_in_raw[inKey].ip!=null">
                   <template v-if="typeof row.c_packet_in_raw[inKey].hostname[0]!=='undefined'">
                        ({{ row.c_packet_in_raw[inKey].ip }})
                   </template>
                   <template v-else>
                       {{ row.c_packet_in_raw[inKey].ip }}
                   </template>
               </template><br>
                 </span>
                    <span v-for="(deepMessageLine, deepKey) in inMessageLine" :key="deepKey" class="ml-3">
                       <template v-if="deepMessageLine['type']!=null && deepMessageLine['result']!=null">
                              {{ deepMessageLine['type'] }} ({{ getConvert(deepMessageLine['step_content'], deepMessageLine['type'])}})
                           <span class="rule">{{ deepMessageLine['operator'] }}</span> "{{ deepMessageLine['check_content'] }}":
                           <span :style="getColor(deepMessageLine['result'])"> {{ getCheckResult(deepMessageLine['result']) }}</span><br/>
                          </template>
                      </span>
                  </span>
               </template>
           </template>
           <template v-else>
               <template v-if="row.c_messages[givenKey]!=null && (givenKey === 'uplink' || givenKey === 'downlink')">
                  <span class="sectionHeader">Check:</span> {{ givenKey.charAt(0).toUpperCase() + givenKey.slice(1) }}<br/>
                  <span v-for="(inMessageLine, inKey) in messageLine" :key="inKey">
                      <template v-for="(deepMessageLine, deepKey) in inMessageLine">
                          <span v-if="deepMessageLine['type']!=null" :key="deepKey" class="ml-3">
                              {{ deepMessageLine['type'] }} ({{
                              getConvert(deepMessageLine['step_content'], deepMessageLine['type'])
                              }}) <span class="rule">{{ deepMessageLine['operator'] }}</span> "{{ deepMessageLine['check_content'] }}":<span
                              :style="getColor(deepMessageLine['result'])"> {{ getCheckResult(deepMessageLine['result']) }}</span>
                           <br/>
                          </span>
                      </template>
                   </span>
               </template>
               <template v-else>
                  <template v-if="messageLine && typeof messageLine['name']!='undefined' && messageLine['name']!=null">
                    <span v-if="indexParent===0" class="sectionHeader">Check:<br></span>
                     <span class="ml-3">
                      <span v-html="constructNameString(messageLine['name'])"></span>
                      <span :style="getColor(messageLine['value'])">{{ getCheckResult(messageLine['value']) }}</span><br/>
                   </span>
                  </template>
               </template>
           </template>
          </span>
        <template v-if="typeof row.c_messages['msg']!='undefined' && row.c_messages['msg']!=null">
          <span>{{ row.c_messages['msg'] }}</span>
        </template>
        <template
            v-else-if="typeof row.c_messages[0]!='undefined' && row.c_messages[0].includes('Timeout')||
                               typeof row.c_messages[0]!='undefined' && typeof row.c_messages[0] =='string'">
          <span>{{ row.c_messages[0] }}</span>
        </template>
      </template>
      <template  v-else-if="row.rawType === 'RADIUS'">
          <div class="ml-3" v-if="Array.isArray(row.c_messages) && row.c_messages.length !== 0 ">
            <span class="sectionHeader">Check:</span>
            <template v-if="checkRadiusCheckVar(row.c_messages)">
             <span v-for="(messageLine, givenKey,indexParent) in radiusCheckVal(row.c_messages)" :key="indexParent">
                 <template v-if="messageLine['type']!=null && messageLine['operator']!=='save in variable'">
                    <div class="ml-3">{{ messageLine['typeName'] }} ({{
                                 messageLine['step_content']
                                 }}) <span class="rule">{{ messageLine['operator'] }}</span> "{{ messageLine['check_content'] }}": <span :style="getColor(messageLine['result'])">{{ getCheckResult(messageLine['result']) }}</span></div>
                         </template>
               </span>
              </template>
              <template v-else>
                  <div class="ml-3">no checks</div>
              </template>
              <template v-if="checkRadiusSaveVar(row.c_messages)">
                  <span class="sectionHeader">Store Variable:</span>
                  <span v-for="(messageLine, givenKey,indexParent) in radiusCheckVal(row.c_messages)" :key="indexParent">
                         <template v-if="messageLine['type']!=null && messageLine['operator']==='save in variable'">
                             <div class="ml-3">{{ messageLine['typeName'] }} ({{
                                 messageLine['step_content']
                                 }}) <span class="rule">store in</span> "{{ messageLine['check_content'] }}": <span :style="getColor(messageLine['result'])">{{ getCheckResult(messageLine['result']) }}</span></div>
                         </template>
                   </span>
              </template>
          </div>
          <div v-else-if="row.c_messages.length !== 0">
          {{ row.c_messages }}
         </div>
      </template>
      <template v-else-if="row.rawType.includes('Explorer_VF')">
        <div v-if="row.additionalParameters">
          Additional Parameters: {{row.additionalParameters}}
        </div>
<!--        <div v-for="(messageLine, vfKey) in sortObjectMessage(row.c_messages,row)" :key="vfKey">
          <div v-for="(messageLinePart, i) of messageLine.split('\n')" :key="i">{{ messageLinePart }}</div>
        </div>-->
          <div v-if="typeof row.c_messages.msg!=='undefined' && row.c_messages.msg.length>0">
            <div v-for="(messageLinePart, i) of row.c_messages.msg.split('\n')" :key="'A'+ i">
              {{ messageLinePart }}
            </div>
          </div>

          <div class="mt-2"></div>
          <template v-if="row.c_messages['evalV2']">
            <div class="sectionHeader">Check:</div>
            <template v-for="(deepMessageLine, deepKey) in row.c_messages['evalV2']">
                          <span v-if="deepMessageLine['type']!=null" :key="deepKey" class="ml-3">
                              {{ deepMessageLine['type'] }} ({{ getExScriptType(deepMessageLine['type']) }}({{getConvert(deepMessageLine['step_content'], deepMessageLine['type'])
                                  }}) <span class="rule">{{
                              deepMessageLine['operator']
                            }}</span> "{{ deepMessageLine['check_content'] }}":<span
                              :style="getColor(deepMessageLine['result'])"> {{ getCheckResult(deepMessageLine['result']) }}</span>
                           <br/>
                          </span>
            </template>
          </template>
      </template>
      <messageObjectFormatter v-else :message="row.c_messages" :row="row"></messageObjectFormatter>
    </template>
  </div>
</template>
<script>
import options from '@/cfg/options.json';
import {convertMBits, objSortBy, constructNameString, radiusAttrNames,sortObjectMessage} from '@/js/helper.js';
import xbasicModemTable from '@/components/legacy/xbasicModemTable.vue';
import xbasicModemRequestTable from '@/components/legacy/xbasicModemRequestTable.vue';
import xtwampTable from '@/components/legacy/xtwampTable.vue';
import xtwampRequestTable from '@/components/legacy/xtwampRequestTable.vue';
import xsipCallRequestTable from '@/components/legacy/xsipCallRequestTable.vue';
import xsipCallTable from '@/components/legacy/xsipCallTable.vue';
import xiperfTable from '@/components/legacy/xiperfTable.vue';
import xiperfRequestTable from '@/components/legacy/xiperfRequestTable.vue';
import xpingTable from '@/components/legacy/xpingTable.vue';
import xpingRequestTable from '@/components/legacy/xpingRequestTable.vue';
import xdnsTable from '@/components/legacy/xdnsTable.vue';
import xdnsRequestTable from '@/components/legacy/xdnsRequestTable.vue';
import xudpstTable from '@/components/legacy/xudpstTable.vue';
import xudpstRequestTable from '@/components/legacy/xudpstRequestTable.vue';
import xbbmTable from '@/components/legacy/xbbmTable.vue';
import xtraceRouteTable from '@/components/legacy/xtraceRouteTable.vue';
import xtraceRouteRequestTable from '@/components/legacy/xtraceRouteRequestTable.vue';
import xspeedTestTable from '@/components/legacy/xspeedTestTable.vue';
import xLibreSpeedTestTable from '@/components/legacy/xLibreSpeedTestTable.vue';
import xLibreSpeedTestRequestTable from '@/components/legacy/xLibreSpeedTestRequestTable.vue';
import xrttCheckRequestTable from '@/components/legacy/xrttCheckRequestTable.vue';
import xrttCheckTable from '@/components/legacy/xrttCheckTable.vue';
import messageObjectFormatter from '@/components/legacy/messageObjectFormatter.vue';

export default {
  data() {
    return {
      mscPopup: false,
      fileDownloadPopup: false,
      hiddenArray: options.hiddenArray,
      operatorList:options.operators,
      errorList:options.evalCheckError,
      radiusAttributesCollection:[]
     };
  },
  components: {
    xsipCallRequestTable,
    xsipCallTable,
    xudpstTable,
    xudpstRequestTable,
    messageObjectFormatter,
    xspeedTestTable,
    xbbmTable,
    xtraceRouteTable,
    xtraceRouteRequestTable,
    xdnsTable,
    xdnsRequestTable,
    xpingTable,
    xpingRequestTable,
    xiperfTable,
    xiperfRequestTable,
    xLibreSpeedTestTable,
    xLibreSpeedTestRequestTable,
    xrttCheckRequestTable,
    xrttCheckTable,
    xtwampRequestTable,
    xtwampTable,
    xbasicModemRequestTable,
    xbasicModemTable
  },
  props: ['row', 'result'],
  computed: {
    commentMessage() {
      return this.row['c_messages'].replace('\\n', ''); //sometime kai send this \\n no idea why!
    },
    iperfRequestData() {
      let dataStack = this.row.c_packet_out_show_raw;
      if (typeof dataStack.tos != 'undefined' && (dataStack.tos === 0 || dataStack.tos === '0x0')) {
        delete dataStack.tos;
      }
      return dataStack;
    },
    pingRequestData() {
      let dataStack = this.row.c_packet_out_show_raw;
      if (typeof dataStack.tos != 'undefined' && (dataStack.tos === 0 || dataStack.tos === '0x0')) {
        delete dataStack.tos;
      }
      if (typeof dataStack.df != 'undefined' && dataStack.df === 0) {
        delete dataStack.df;
      }
      if (typeof dataStack.df != 'undefined' && dataStack.df === 1) {
        dataStack.df = 'True';
      }
      return dataStack;
    },
    traceRouteRequestData() {
      let dataStack = this.row.c_packet_out_show_raw;
      if (typeof dataStack.protocol != 'undefined' && dataStack.protocol === 'icmp' && typeof dataStack.port !=
          'undefined') {
        delete dataStack.port;
      }
      return dataStack;
    },
    title() {
      return this.row['c_type'];
    },
  },
  mounted() {
    if(this.row['c_type']=="RADIUS"){
      this.getAttributes();
    }
  },
  methods: {
    getExScriptType(code){
      return options.exCustomScriptType.find(a => a[0] == code)[1];
    },
    sortObjectMessage:sortObjectMessage,
    getAttributes() {
      this.services.dictionary.getRadiusAttributes((attributes) => {
        this.radiusAttributesCollection = radiusAttrNames(attributes);
      });
    },
      constructNameString(nameString){
          return constructNameString(nameString,this.operatorList);
      },

/*    getRadiusAttributes() {
          this.getRequest('https://dictionary-service.degust-services.acctopus.com/dictionary-service/v1/radius',
              {Project: this.result.projectHash},
              null,
              (attributes) => {
                  this.radiusDict=attributes;
              });
     },*/

    radiusCheckVal(cMessage){
        var radiusDict = this.radiusAttributesCollection;
        var result = cMessage;
        if(typeof cMessage==='object'){
            cMessage.forEach(function(el,index){
                if(typeof el.type!=='undefined' && el.type!==null){
                    //cMessage[index]['typeName'] = radiusDict[el.type];
                    if(typeof radiusDict.find(a => a[0] == el.type)!=='undefined'){
                      cMessage[index]['typeName'] = radiusDict.find(a => a[0] == el.type)[1];
                    }
                }
            });
         // result = cMessage;
        }
        //console.log(cMessage);
        if(cMessage.length>0){
              result =  cMessage.slice().sort(objSortBy('typeName')); //slice must be present otherwise infinate loops issue comes
        }
        return result;
    },

    checkRadiusCheckVar(cMessage) {
          var checkVar = false;
          if (typeof cMessage === 'object') {
              cMessage.forEach(function (el) {
                  if (typeof el.operator !== 'undefined' && el.operator!== 'save in variable') {
                      checkVar = true;
                  }
              });
          }
          return checkVar;
      },

    checkRadiusSaveVar(cMessage) {
      var checkSaveVar = false;
      if (typeof cMessage === 'object') {
        cMessage.forEach(function (el) {
          if (typeof el.operator !== 'undefined' && el.operator === 'save in variable') {
            checkSaveVar = true;
          }
        });
      }
      return checkSaveVar;
    },

    applyBreak(text) {
      let textArray = text.split(',').map((item) => item.trim());
      let result = '';
      textArray.forEach((stringInfo, index) => {
        result += stringInfo + ',';
        if (index === 3) {
          result = result + '\r\n';
        }
      });
      return result.replace(/,(\s+)?$/, ''); // remove last comma!
    },

    resumePause() {
      this.frameworkAxiosRequest({
        method: 'POST',
        url: 'serve.php?f=testing&f2=testInfo&function=getInfo',
        dataType: 'json',
        data: {
          n_id: this.$route.query.n_id,
          n_id_key: this.$route.query.n_id_key,
          requestType: 'ajax',
          function: 'resumeTest',
          testQueueId: this.result.report_id,
          testrunId: this.row['n_id'],
        },
      }).then(function (response) {
        //to do ??
        console.log(response);
      }).catch(function (response) {
        console.log(response);
      });
    },

    downloadMinioFile(source, path) {
      let URL =
          'serve.php?f=global&f2=mServer&action=createFile&source=' + source;
      console.log('path', path);
      window.location.href = URL;
    },
    getCountrySrc(messageLine) {
      let countryDetail = messageLine.split(':');
      return countryDetail[0];
    },
    getCountryName(messageLine) {
      let countryDetail = messageLine.split(':');
      return countryDetail[1];
    },
    getStyle(messageLine) {
      let style = '';
      if (
          !this.empty(messageLine['valueColor']) &&
          Array.isArray(messageLine['valueColor'])
      ) {
        style = 'color:rgb(' + messageLine['valueColor'].join(',') + ')';
      }
      return style;
    },
    getColor(val) {
      let color;
      if (val === 'ok') {
        color = '#008000';
      } else if (val === 'error') {
        color = 'red';
      } else if (val === 'warning') {
        color = 'rgb(255, 200, 150)';
      } else if (val === 'runtime_warning') {
        color = 'rgb(255, 152, 0)';
      } else if (val === 'runtime_error') {
        color = 'rgb(255, 87, 34)';
      } else {
        color = 'black';
      }
      return 'color:' + color;
    },
    getCheckResult(error){
      let result = error;
       if(typeof this.errorList[error]!=="undefined"){
          result = this.errorList[error];
       }
       return result;
    },
    getConvert(source, type) {
      //console.log(source, type);
      let result = source;
      if (source !== 0 && type === 'bitrate') {
        result = convertMBits(source);
      } else if (source !== 0 && (type === 'ping' || type === 'jitter')) {
        result = Number(source).toFixed(2) + ' ms';
      } else if (type === 'lost') {
        result = Number(source);
      } else if (type === 'retransmits') {
        result = Number(source);
      } else if (source !== 0 && (type === 'downlink' || type === 'uplink') && (this.row['rawType']==="Explorer_BBM" || this.row['rawType']==="Explorer_iperf")) {
        result = convertMBits(source); //Base: "bit/s"
      } else if (source !== 0 && (type === 'downlink' || type === 'uplink')) {
        result = Number(source).toFixed(2) + ' Mbit/s';
      } else if (source !== 0 && (type === 'avg' || type === 'max' || type === 'min' || type === 'latency'|| type === 'mdev')) {
        result = (Number(source) / 1000000).toFixed(2) + ' ms';
      } else if (source !== 0 && (type === 'L3Mbps' || type === 'L2Mbps' || type === 'L1Mbps' || type === 'L0Mbps' || type === 'avgL3Mbps')) {
        result = Number(source).toFixed(2) + ' Mbps';
      } else if (source === '' || result == null) {
        result = 0;
      }else if(this.row['rawType']=="Explorer_SIP_CALL"){
        const sipCallAttrUnits = this.result.additional.sipCallAttrUnits;
        if(source!='' && type!='' && typeof sipCallAttrUnits.find(a => a[0] == type)[1]!=='undefined'){
          let baseUnit = sipCallAttrUnits.find(a => a[0] == type)[1];
          if(baseUnit=='ms'){
            result = parseFloat(source)+" "+baseUnit;
          }else if(baseUnit=='percent'){
            result = source+" %";
          }else if(baseUnit=='ms'){
          result = source+" ms";
         }
        }
      }
      return result;
    },
    getMappedEvalType(input){
      let value = input;
      if(this.row['rawType']=="Explorer_SIP_CALL"){
        const sipCallAttr = this.result.additional.sipCallAttr;
        if(input!='' && typeof sipCallAttr.find(a => a[0] == input)!=='undefined' && typeof sipCallAttr.find(a => a[0] == input)[1]!=='undefined'){
            value = sipCallAttr.find(a => a[0] == input)[1];
        }
      }
      return value;
    },
    resumePauseAndReturnFalse() {
      this.resumePause();
      return false;
    },
  },
};
</script>
<style>
.innerClassTable td {
  padding: 0 2px !important;
}

.sectionHeader {
  color: #3F6EA3;
  font-weight: bold;
}

.rule {
  color: #3F6EA3;
}
</style>