<template>
  <div class="mb-2">
    <table style="width: 100%; max-width: 360px" class="innerClassTable">
      <colgroup>
        <col style="width: 33%" />
        <col style="width: 33%" />
        <col style="width: 33%" />
      </colgroup>
      <tbody>
        <tr class="primary white--text">
            <template v-for="(value,index) in newItems">
            <th v-if="index!='message'" :key="index" class="primary">
               <span > {{ languagePack("bbmTable", index) }}</span>
           </th>
            </template>
        </tr>
        <tr>
            <template v-for="(value,index) in newItems">
                <td style="text-align: center" v-if="index!='message'" :key="index">
                    <span v-if="index=='ping'">{{ value }} ms</span>
                    <span v-else>{{ convertMBits(value) }}</span>
                </td>
            </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { objectCustomSort,convertMBits } from "@/js/helper.js";

  export default {
  props: {
    item: Object,
  },
 computed: {
      newItems(){
        var sortOrder = ['uplink','downlink','ping','message'];
        return objectCustomSort(sortOrder,this.item);
      }
 },
  methods: {
    convertMBits(value) {
      return convertMBits(value);
    },
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
}
table td {
  border: thin solid rgba(0, 0, 0, 0.12);
  padding: 5px;
}
table th {
  border: thin solid rgba(255, 255, 255);
  padding: 5px;
}
table th:nth-child(1), th:nth-child(2) {
    border-right-color: white !important;
}
.coloredBack,
.coloredBack:hover {
  background-color: #deebff;
}
</style>